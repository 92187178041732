<template>
  <div class="faq">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <FaqItem v-for="(item, index) in faqs" :key="index" :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import FaqItem from "./FaqItem.vue";
export default {
  computed: {
    ...mapState({
      faqs: (state) => state.faq.faqs,
    }),
  },
  components: {
    FaqItem,
  },
};
</script>