<template>
  <div
    class="team-item team-item--simple mb-4 faq-item"
    :class="{ 'faq-item--open': show }"
    @click="showAnswer"
  >
    <div class="team-item__inner faq-item__inner">
      <div class="faq-item__title">
        {{ item.title }}
      </div>
      <transition
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @enter-cancelled="enterCancelled"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
        @level-cancelled="leaveCancelled"
        v-bind:css="false"
      >
        <div class="faq-item__answer editor" v-show="show" v-html="item.description">
        </div>
      </transition>
      <b-icon icon="chevron-down" class="faq-item__icon"></b-icon>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showAnswer() {
      this.show = !this.show;
    },
    beforeEnter(el) {
      el.style.height = 0;
      el.style.overflow = "hidden";
    },
    enter(el, done) {
      const increaseHeight = () => {
        if (el.clientHeight < el.scrollHeight) {
          const height = `${parseInt(el.style.height) + 5}px`;
          el.style.height = height;
        } else {
          clearInterval(this.enterInterval);
          done();
        }
      };
      this.enterInterval = setInterval(increaseHeight, 35);
    },
    afterEnter() {},
    enterCancelled() {
      clearInterval(this.enterInterval);
    },
    beforeLeave() {},
    leave(el, done) {
      const decreaseHeight = () => {
        if (el.clientHeight > 0) {
          const height = `${parseInt(el.style.height) - 5}px`;
          el.style.height = height;
        } else {
          clearInterval(this.leaveInterval);
          done();
        }
      };
      this.leaveInterval = setInterval(decreaseHeight, 35);
    },
    afterLeave() {},
    leaveCancelled() {
      clearInterval(this.leaveInterval);
    },
  },
};
</script>
