<template>
  <div class="faq">
    <page-cover :cover="cover"  v-if="cover" />
    <FaqPage />
  </div>
</template>
<script>
import PageCover from "@/components/shared/PageCover.vue";
import FaqPage from "../../components/faq/FaqPage.vue";
import { mapGetters } from "vuex";

export default {
  name: "faq",
  computed: {
    ...mapGetters({
      cover: "pageCover/getFaq",
    }),
  },
  created() {
    this.$store.dispatch("faq/getFaqs");
  },
  components: {
    PageCover,
    FaqPage,
  },
};
</script>